import BaseService from "./base.service";

class TestimonialsService {
    testimonials(page = null, search = null) {
        let params = [];
        if(search) {
            params.push({'key':'q', 'value':search});
        }
        return BaseService.apiGetRequest('testimonial', page, params);
    }    
    get(id) {
        return BaseService.apiGetRequest('testimonial/'+id);
    }
    add(testimonial) {
        return BaseService.apiPostRequest('testimonial/add', testimonial);
    }
    delete(testimonial) {
        return BaseService.apiPostRequest('testimonial/delete/', testimonial);
    }
    update(id, testimonial) {
        return BaseService.apiPostRequest('testimonial/'+id, testimonial);
    }

    search(page=null) {
        return BaseService.apiGetRequest('testimonial/search', page);
    }

}
export default new TestimonialsService();